
import { Vue, Options } from "vue-class-component";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { MiddlewareController } from "@/app/ui/controllers/MiddlewareController";
import { MiddlewareDetail } from "@/domain/entities/Middleware";
import { ApiStateData } from "@/domain/entities/Api";

@Options({
  components: {
    DetailLayout
  },
  computed: {}
})
export default class Detail extends Vue {
  apiGetData = new ApiStateData();
  mounted() {
    this.fetchDetail();
  }

  get id(): string {
    return this.$route.params.id.toString();
  }

  get title() {
    return this.detail.username;
  }

  goBack() {
    this.$router.push("/admin/middleware/mapping-code");
  }

  goToEdit() {
    this.$router.push(`/admin/middleware/mapping-code/edit/${this.id}`);
  }

  detail = new MiddlewareDetail()
  async fetchDetail() {
    this.apiGetData.loading = true;
    this.apiGetData.errorType = "";
    try {
      this.detail = await MiddlewareController.getDetailMiddleware({
        type: "mapping-code",
        id: Number(this.id)
      });
    } catch (err) {
      this.apiGetData.errorType = parsingErrorResponse(err).type;
    } finally {
      this.apiGetData.loading = false;
    }
  }

  formatDate(date: any) {
    return formatDate(date);
  }

}
